import React, { useState } from 'react';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import FormField from './FormField';

const PasswordField = (({keyIcon=false, onChange, name, placeholder, className}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  return (
    <>
      {keyIcon && (
        <div className="form__form-group-icon">
          <KeyVariantIcon />
        </div>
      )}
      <input
        className={className}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        type={passwordShown ? 'text' : 'password'}
      />
      <button
        className={`form__form-group-button${passwordShown ? ' active' : ''}`}
        type="button"
        onClick={() => setPasswordShown(shown => !shown)}
      ><EyeIcon />
      </button>
    </>
  );
});

export default PasswordField;
