import React, { useEffect, useState } from 'react';
import { Formik, Field, Form} from 'formik';
import { Checkbox, Select, DatePicker, Space, TimePicker, Input, Button, Row, Col, TreeSelect, InputNumber } from 'antd';
import moment from 'moment';
// import CIcon from '@coreui/icons-react'
// import { freeSet } from '@coreui/icons'
import { Editor } from '@tinymce/tinymce-react';
import CropImageField from '../CropImageField';

const { Option } = Select;

const CustomForm = ({handleSubmit, fields, closeModal, editData, editable, loading, col}) => {
  const [ colWidth, setColWidth ] = useState(24)

  useEffect(() => {
    switch(col){
      case 2: 
        return setColWidth(12);
      case 3: 
        return setColWidth(8);
      default: return setColWidth(24)
    }
  },[col])
  
  const getInitValue = () => {
    let tmp = {}
    fields.map((item, i) => {
      if(editData) {
        if(item.type == 'date') {
          tmp[item.dataIndex] = editData[item.dataIndex] != null ? moment(editData[item.dataIndex], 'YYYY-MM-DD HH:mm:ss') : moment()
        }
        else if(item.type == 'time') {
          tmp[item.dataIndex] = editData[item.dataIndex] != null ? moment(editData[item.dataIndex], 'HH:mm:ss') : null
        }
        else if(item.type == 'dropdown') {
          tmp[item.dataIndex] = editData[item.dataIndex]
        }
        else {
          tmp[item.dataIndex] = editData[item.dataIndex]
        }
      }
      else {
        item.type == 'orderIndex' ?   
        tmp[item.dataIndex] = item.defaultIndex
        :
        tmp[item.dataIndex] = item.value;
      }
    })
    return tmp
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={getInitValue()}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values, resetForm)         
      }}>
      {
        ({
          values,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          handleSubmit,
          handleChange,
          setFieldValue,
          resetForm
        }) => {
          return (
            <Form className='text-left'>
              <Row gutter={col ? 12 : 0}>                
              {
                fields.sort(function(a,b){ return a.formOrder - b.formOrder; }).map((item, i) => {
                  if(item.renderOnForm != false)
                  switch (item.type){
                    case 'date':
                      return (
                        <Col key={i} xs={item.offset ? item.offset : colWidth}>
                          <div className='form__form-group'>
                            <span className='form__form-group-label'>
                              {`${item.title} : `}
                            </span>
                            <div className='form__form-group-field'>
                              <DatePicker
                                defaultValue={values[item.dataIndex]}
                                allowClear
                                value={values[item.dataIndex]}
                                name={item.dataIndex}
                                style={{flex: 1, width: '100%'}}
                                format={item.format ? item.format : 'YYYY-MM-DD'}
                                onChange={(date, dateString) => setFieldValue(item.dataIndex, date)}
                                showTime={item.showTime ? item.showTime : false}
                              />
                            </div>
                          </div>
                        </Col>
                      )
                    case 'dropdown':
                      return (
                        <Col key={i} xs={item.offset ? item.offset : colWidth} >
                          <div className='form__form-group'>
                            <span className='form__form-group-label'>
                              {`${item.title} : `}
                            </span>
                            {item.warning && 
                              <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: '10px',
                                            backgroundColor:'#ffefbc', 
                                            color: '#c69843', 
                                            border: '1px solid #fbdd87', 
                                            borderRadius: '5px', 
                                            padding: '2px 5px',
                                            fontStyle: 'italic'}}> 
                                {/* <CIcon style={{margin: '0 10px'}} 
                                        content={freeSet.cilWarning}
                                        size='xl' /> */}
                                {item.warning} 
                              </div>}
                            <Select
                              style={{flex: 1, width: '100%'}}
                              defaultValue={values[item.dataIndex]}
                              value={values[item.dataIndex]}
                              mode={item.multiple ? 'multiple' : 'default'}
                              placeholder={`${item.title} сонгох`}
                              allowClear={item.clearable ? item.clearable : false}
                              onChange={(value) => { 
                                item.changeCallback &&                            
                                item.changeCallback(value)
                                setFieldValue(item.dataIndex, value)
                              }}>
                              {
                                item.options.map((option, optionIndex) => {
                                  return (
                                    <Option key={optionIndex} value={option.value}>{option.label}</Option>
                                  )
                                })
                              }
                            </Select>
                          </div>
                        </Col>
                      )
                    case 'treeselect':
                      return (
                        <Col key={i} xs={item.offset ? item.offset : colWidth}>
                          <div className='form__form-group'>
                            <span className='form__form-group-label'>
                              {`${item.title} : `}
                            </span>
                            <div className='form__form-group-field'>
                              <TreeSelect
                                treeCheckable={true}
                                style={{flex: 1, width: '100%' }}
                                value={values[item.dataIndex]}
                                /* dropdownStyle={{ maxHeight: 400, overflow: 'auto' }} */
                                treeData={item.options}
                                placeholder={`${item.title} сонгох`}
                                treeDefaultExpandAll
                                onChange={(value) => {                            
                                  setFieldValue(item.dataIndex, value)
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      )
                    case 'check':
                      return (
                        <Col key={i} xs={item.offset ? item.offset : colWidth}>
                          <div className='form__form-group'>
                            {/* <span className='form__form-group-label'>
                              {`${item.title} : `}
                            </span> */}
                            <div className='form__form-group-field'>
                              <Checkbox 
                                checked={values[item.dataIndex]}
                                style={{marginLeft: '10px'}}
                                onChange={(e) => {
                                  setFieldValue(item.dataIndex, !values[item.dataIndex])
                                }}>
                                {item.title}
                              </Checkbox>
                            </div>
                          </div>
                        </Col>
                      )
                    case 'textArea':
                      return (
                        <Col key={i} xs={item.offset ? item.offset : colWidth}>
                          <div className='form__form-group'>
                            <span className='form__form-group-label'>
                              {`${item.title} : `}
                            </span>
                            <div className='form__form-group-field'>
                              <Field
                                disabled={item.fieldDisabled}
                                name={item.dataIndex}
                                type={item.type}
                                rows={3}
                                maxLength={item.maxLength ? item.maxLength : 524288}
                                placeholder={`${item.title} оруулах`}
                                onChange={(e) => setFieldValue(item.dataIndex, e.target.value)}
                                as={Input.TextArea}>
                              </Field>
                            </div>
                          </div>
                        </Col>
                      )
                    case 'textEditor':
                      return (
                        <Col key={i} xs={item.offset ? item.offset : colWidth}>
                          <div className='form__form-group'>
                            <span className='form__form-group-label'>
                              {`${item.title} : `}
                            </span>
                            <div className='form__form-group-field'>
                              <Editor
                                apiKey='8s6jo6a9q54ae8b09itxfscodyd3f117pavntnm6mx6sw311'
                                value={values[item.dataIndex]}
                                init={{         
                                  height:'300px',
                                  width: '100%',               
                                  plugins: [
                                    'advlist autolink lists link image', 
                                    'charmap print preview anchor help',
                                    'searchreplace visualblocks code',
                                    'insertdatetime media table paste wordcount'
                                  ],
                                  toolbar:
                                    'undo redo | formatselect | bold italic | \
                                    alignleft aligncenter alignright | \
                                    bullist numlist outdent indent | help',
                                  block_formats: 'Paragraph=p;Header 1=h2;Header 2=h3;',
                                  style_formats: [
                                  
                                  ],
                                  file_picker_callback: function (callback, value, meta) {
                                    var input = document.createElement('input');
                                    input.setAttribute('type', 'file');
                                    input.setAttribute('accept', 'image/*');         
                                    input.onchange = function () {
                                      var file = this.files[0];
                                      var reader = new FileReader();
                                      reader.onload = function (e) {
                                        callback(e.target.result, {
                                          alt: file.name
                                      });
          
                                      };
                                      reader.readAsDataURL(file);
                                    };
                                    input.click();
                                  },                                  
                                }}                        
                                onEditorChange={(e, editor) => {
                                  // let node = editor.selection.getNode()
                                  // node.setAttribute('id', 'test')
                                  setFieldValue(item.dataIndex, e)
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      )
                    case 'file':
                      return (
                        <Col key={i} xs={item.offset ? item.offset : colWidth}>
                          <div className='form__form-group'>
                            <span className='form__form-group-label'>
                              {`${item.title} : `}
                            </span>
                            <div className='form__form-group-field'>
                              <Input
                                style={{overflow: 'hidden'}}
                                name={item.dataIndex}
                                type={'file'}
                                placeholder={`${item.title} оруулах`}
                                onChange={e => setFieldValue(item.dataIndex, e.currentTarget.files[0])}>
                              </Input>
                            </div>
                          </div>
                        </Col>
                      )
                    case 'cropImg':
                      return (
                        <Col key={i} xs={item.offset ? item.offset : colWidth}>
                          <div className='form__form-group'>
                            <span className='form__form-group-label'>
                              {`${item.title} : `}
                            </span>
                            <div className='form__form-group-field'>
                              <CropImageField
                                onChange={(e) => setFieldValue(item.dataIndex, e)}
                                aspect={item.aspect}
                              />
                            </div>
                          </div>
                        </Col>
                      )
                    case 'orderIndex':
                      return (
                        <Col key={i} xs={item.offset ? item.offset : colWidth}>
                          <div className='form__form-group'>
                            <span className='form__form-group-label'>
                              {`${item.title} : `}
                            </span>
                            <div className='form__form-group-field'>
                              <Field
                                disabled={item.fieldDisabled}
                                defaultValue={item.defaultIndex}
                                name={item.dataIndex}
                                type='number'
                                placeholder={`${item.title} оруулах`}
                                value={values[item.dataIndex]}
                                as={Input}>
                              </Field>
                            </div>
                          </div>
                        </Col>
                      )
                    case 'number':
                      return (
                        <Col key={i} xs={item.offset ? item.offset : colWidth}>
                          <div className='form__form-group'>
                            <span className='form__form-group-label'>
                              {`${item.title} : `}
                            </span>
                            <div className='form__form-group-field'>
                              <Field
                                disabled={item.fieldDisabled}
                                name={item.dataIndex}
                                type={item.type}
                                max={item.max}
                                min={item.min}
                                placeholder={`${item.title} оруулах`}
                                value={values[item.dataIndex]}
                                as={Input}>
                              </Field>
                            </div>
                          </div>
                        </Col>
                      )
                    case 'price':
                      return (
                        <Col key={i} xs={item.offset ? item.offset : colWidth}>
                          <div className='form__form-group'>
                            <span className='form__form-group-label'>
                              {`${item.title} : `}
                            </span>
                            <Row>
                              <div className='form__form-group-field'>
                                <InputNumber
                                  style={{width:'100%'}}
                                  value={values[item.dataIndex]}
                                  name={item.dataIndex}
                                  formatter={value => `${new Intl.NumberFormat().format(value)}`}
                                  onChange={(e) => {
                                    setFieldValue(item.dataIndex, e)
                                  }}
                                />
                              </div>
                            </Row>                      
                          </div>
                        </Col>
                      )
                    case 'time':
                      return (
                        <Col key={i} xs={item.offset ? item.offset : colWidth}>
                          <div className='form__form-group'>
                            <span className='form__form-group-label'>
                              {`${item.title} : `}
                            </span>
                            <div className='form__form-group-field'>
                              <TimePicker
                                defaultValue={moment()}
                                value={values[item.dataIndex]}
                                style={{flex: 1, width: '100%'}}
                                disabled={item.fieldDisabled}
                                name={item.dataIndex}
                                placeholder={`${item.title} оруулах`}
                                onChange={(date, dateString) => {setFieldValue(item.dataIndex, date)}}
                                format={'HH:mm'}>
                              </TimePicker>
                            </div>
                          </div>
                        </Col>
                      )
                    case 'view':
                      return (
                        <Col key={i} xs={item.offset ? item.offset : colWidth}>
                          <div className='form__form-group'>
                            <span className='form__form-group-label'>
                              {`${item.title} : `}
                            </span> 
                            <div className='form__form-group-field'>
                              <div style={{flex: 1, width: '100%', minHeight:'32px',  border: '1px solid #c2c8d1', padding: '4px 7px'}}>
                                  { values[item.dataIndex] }
                              </div>
                            </div>
                          </div>
                        </Col>
                      )
                    case 'password':
                      return (
                        <Col key={i} xs={item.offset ? item.offset : colWidth}>
                          <div className='form__form-group'>  
                            <span className='form__form-group-label'>
                              {`${item.title} : `}
                            </span> 
                            <div className='form__form-group-field'>
                              <Field
                                disabled={item.fieldDisabled}
                                name={item.dataIndex}
                                type={item.type}
                                rows={5}
                                placeholder={`${item.title} оруулах`}
                                as={Input.Password}>
                              </Field>
                            </div>
                          </div>
                        </Col>
                      )
                    default:
                      return (
                        item.type &&
                        <Col key={i} xs={item.offset ? item.offset : colWidth}>
                          <div className='form__form-group'>
                            <span className='form__form-group-label'>
                              {`${item.title} : `}
                            </span>
                            <div className='form__form-group-field'>
                              <Field
                                label={item.title}
                                disabled={item.fieldDisabled}
                                name={item.dataIndex}
                                type={item.type}
                                placeholder={`${item.title} оруулах`}
                                value={values[item.dataIndex]}
                                as={Input}>
                              </Field>
                            </div>
                          </div>
                        </Col>
                      )                    
                  }
                })
              }
              </Row>
              <div className='d-flex justify-content-end mb-3'>
                {
                  (editable == null || editable) &&
                  <Button
                    className='mr-3'
                    type='primary'
                    loading={loading}
                    onClick={(e) => handleSubmit(values, resetForm)}
                    >
                    Хадгалах
                  </Button>
                }
                {
                  closeModal &&
                  <Button
                    onClick={() => {
                      resetForm()
                      closeModal()
                    }}
                    disabled={loading}
                    color="secondary">
                    Хаах
                  </Button>
                }
              </div>
            </Form>
          )
        }
      }
    </Formik>
  );
}

export default CustomForm;