import React, { useContext, useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse, Modal } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import ls from '../../../utils/ls';
import { useHistory } from 'react-router-dom';
import { SystemContext } from '../../../contexts';
import CustomForm from 'components/CustomForm';
import axios from 'axios';
import { toast } from 'react-toastify';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [ showModal, setShowModal ] = useState(false)
  const history = useHistory()
  const {state} = useContext(SystemContext)
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const logout = () => {
    ls.clear();
    history.push('/log_in')  
  }

  const handleResetPassword = () => {
    setShowModal(true)
    setIsCollapsed(false)
  }
  const columns = [
    {
      title: 'Хуучин нууц үг',
      name: 'oldpassword',
      dataIndex: 'oldpassword',
      key: 'loldpasswordink',
      type: 'password',
    },
    {
      title: 'Шинэ нууц үг',
      name: 'newpassword',
      dataIndex: 'newpassword',
      key: 'newpassword',
      type: 'password',
    },
    {
      title: 'Нууц үг давтах',
      name: 'confirmpassword',
      dataIndex: 'confirmpassword',
      key: 'confirmpassword',
      type: 'password',
    },
  ]

  const handleSubmitResetPassword = (values) => {
    if(values.newpassword == values.confirmpassword){
      axios({
        method: 'PUT',
        url: 'changepassword',
        data: {
          oldpassword: values.oldpassword,
          newpassword: values.newpassword
        }
      }).then((res) => {
        setShowModal(false)
      }).catch((err) => {
        console.log(err)
      })
    }
    else{
      toast.warning('Та нууц үг зөв давтан оруулна уу !!!')
    }
  }

  return (
    <div className="topbar__profile">
      <button type="button" className="topbar__avatar" onClick={handleToggleCollapse}>
        <img className="topbar__avatar-img" src={Ava} alt="avatar" />
        {state.user && 
          <p className="topbar__avatar-name">{state.user.lastName} {state.user.firstName}</p>
        }
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={handleToggleCollapse}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink title="Байгууллага" icon="apartment" handleClick={() => history.push('/organization')}/>
          <TopbarMenuLink title="Нэвтрэлтийн түүх" icon="inbox" handleClick={() => {history.push('/devicelog'); setIsCollapsed(false)}} />
          <TopbarMenuLink title="Нууц үг солих" icon="lock" handleClick={() => handleResetPassword()}/>
          <div className="topbar__menu-divider" />
          <TopbarMenuLink title="Гарах" icon="exit" handleClick={logout}/>
        </div>
      </Collapse>
      <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)} title='Нууц үг солих'>
        <CustomForm fields={columns} handleSubmit={handleSubmitResetPassword}/>
      </Modal>
    </div>
  );
};

export default TopbarProfile;
