import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import '@/scss/app.scss';
import Router from './Router';
import ScrollToTop from './ScrollToTop';
import { SystemProvider } from '../contexts';
import AxiosComponent from '../utils/axios';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    window.addEventListener('load', () => {
      setIsLoading(false);
      setTimeout(() => setIsLoaded(true), 500);
    });
  }, []);

  return (
    <BrowserRouter>      
      <SystemProvider>  
        <ScrollToTop>
          {!isLoaded && (
            <div className={`load${isLoading ? '' : ' loaded'}`}>
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
              </div>
            </div>
          )}
          <div>
            <Router />
            <ToastContainer
              position="top-center"
              hideProgressBar
              autoClose={2000}
              pauseOnHover={false}
              transition={Slide}
              draggable={false}
              theme='colored'
              bodyClassName='toastBody'
            />
          </div>
        </ScrollToTop>
        <AxiosComponent/>
      </SystemProvider>
    </BrowserRouter>
  );
};

export default App;
